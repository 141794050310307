@import "src/style/main.scss";

.background-slider {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &__img {
        visibility: hidden;

        &--visible {
            visibility: visible;
        }
    }

    &-fade-in {
        animation: fadeIn 1s;
    }
}
