$font-size: 13px;
$font-size-title: 22px;
$font-size-sub-title: 20px;
$font-size-navigation: 18px;
$font-size-navigation-subtitle: 20px;
$header-width: 310px;
$header-padding: 30px;

$font-size-mobile: 11px;
$font-size-title-mobile: 18px;
$font-size-sub-title-mobile: 16px;
$header-width-mobile: 160px;

$mobile-width: 768px;
