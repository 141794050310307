@import "src/style/main.scss";

.gallery {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;

  .loader-ring {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    display: inline-block;
    animation: fadeIn .5s ease-in;
  }
  
  .grid-wrapper {
    width: 100%;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 200px;
    grid-auto-flow: dense;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    & > div > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity .25s ease-in;
      &:hover {
        opacity: .5;
        cursor: pointer;
      }
    }

    & .wide {
      grid-column: span 2;
    }
    & .tall {
      grid-row: span 2;
    }
    & .big {
      grid-column: span 2;
      grid-row: span 2;
    }
  }

  &__footer {
    color: $white;
    font-size: 16px;
    text-align: center;
    padding: 1rem;
  }
}

.slider-wrapper {
  .slider__bg {
    position: fixed;
    top: 0;
  }
}

@media (max-width: 768px) {
  .gallery {
    .grid-wrapper {
      width: 100%;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
      grid-auto-rows: 100px;
    }
  }
}
