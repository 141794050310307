@import "./colors";
@import "./variables";
@import "./mixins";
@import "./arrow";
@import "./loaders";

body {
    font-size: $font-size;
}

a {
    text-decoration: none;
    color: $text-color-light;

    &:hover {
        color: $text-color;
    }
}
