@import "src/style/main.scss";

.slider {
  position: relative;
  margin: 0;
  text-align: center;

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $black;
    z-index: 1000;
  }

  &__img {
    position: relative;
    display: inline-block;
    image-rendering: optimizeQuality;
    -ms-interpolation-mode: bicubic;

    img {
      transition: width .2s ease-in-out, height .2s ease-in-out;
    }

    &__title {
      color: $text-color;
      text-align: center;
      background: $blackTrans50;
      width: 98%;
      padding: 10px 1%;
      animation: fadeIn 1s ease-in-out;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
    }

    &__mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      cursor: url("../../icons/cross-white-1x30x30.png"), crosshair;
    }

    .loader-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #1A1A1A;

      .loader-ring {
        position: absolute;
        top: 45%;
        left: 46%;
      }
    }

    .loader-ring {
      position: absolute;
      top: 45%;
      left: 46%;
    }
  }

  &__btn {
    &--previous, &--next {
      position: absolute;
      height: 100%;
      top: 0;
      width: 20%;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--previous {
      left: 0;

      &:hover {
        opacity: .75;
        cursor: url("../../icons/arrow-left-white-1x30x30.png"), pointer;
      }
    }
    &--next {
      right: 0;

      &:hover {
        opacity: .75;
        cursor: url("../../icons/arrow-right-white-1x30x30.png"), pointer;
      }
    }

    &--close {
      @include cross(2rem, #fff);
      cursor: pointer;
      position: absolute;
      top: -2rem;
      right: -2rem;
      transition: transform .1s ease-in-out;
      z-index: 10;

      &:hover {
        transform: rotate(-90deg);
      }
    }
  }
}

.slider-fade-in {
  animation: fadeIn .6s;
}
