$bg-color: #000;

$text-color: #eee;
$text-color-revert: #000;
$text-color-light: #999;

$whiteTrans50: rgba(255,255,255,.5);
$whiteTrans60: rgba(255,255,255,.6);
$whiteTrans75: rgba(255,255,255,.75);
$whiteTrans90: rgba(255,255,255,.9);
$white: #fff;
$blackTrans50: rgba(0,0,0,.5);
$blackTrans75: rgba(0,0,0,.75);
$blackTrans90: rgba(0,0,0,.9);
$black: #000;
