@import "src/style/main.scss";

@keyframes letterSpacingIn {
    from{
        letter-spacing: 2px;
    }
    to{
        letter-spacing: 8px;
    }
}

@keyframes letterSpacingOut {
    from{
        letter-spacing: 8px;
    }
    to{
        letter-spacing: 2px;
    }
}

.navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $blackTrans75;
    transition: all .2s ease-in-out;
    font-size: $font-size-navigation;
    z-index: 50;

    &__content {
        position: relative;
        top: 30%;
        width: 50%;
        margin: 0 auto 0 35%;
        color: $text-color;
        display: flex;
        justify-content: space-between;

        &__column {
            flex: 1;
            text-align: left;

            .separator {
                width: 25%;
                margin: 10px 0;
            }
        }
    }

    &__subtitle {
        font-size: $font-size-navigation-subtitle;
        letter-spacing: 3px;
        margin-bottom: 5px;
    }

    &__link a {
        display: inline-block;
        text-decoration: none;
        color: $text-color-light;
        padding: 5px 0;
        animation: letterSpacingOut .2s ease-in-out;

        &:hover {
            cursor: pointer;
            color: $text-color;
            animation: letterSpacingIn .4s ease-in-out;
            letter-spacing: 8px;
        }

        .small {
            animation: none;
            letter-spacing: normal;
        }
    }

    &__text {
        color: $text-color-light;
        line-height: $font-size-navigation + 2px;

        .phone {
            font-family: Arial, Helvetica, sans-serif;
            font-size: $font-size-navigation -3px;
            font-weight: bold;
        }
    }

    .small {
        font-weight: normal;
        font-size: $font-size-navigation - 2px;
    }
}

@media screen and (max-width: $mobile-width) {
    .navigation {
        font-size: $font-size-navigation - 2px;

        &__content {
            flex-direction: column;

            &__column {
                margin-bottom: 20px;
            }
        }

        &__subtitle {
            font-size: $font-size-navigation-subtitle - 2px;
        }

        &__text {
            line-height: $font-size-navigation;
    
            .phone {
                font-family: Arial, Helvetica, sans-serif;
                font-size: $font-size-navigation - 5px;
                font-weight: bold;
            }
        }
    
        .small {
            font-weight: normal;
            font-size: $font-size-navigation - 4px;
        }

    }
}