@import "./colors";

@keyframes loader-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.loader-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    & div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: $whiteTrans90;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);

      &:nth-child(1) {
        left: 8px;
        animation: loader-ellipsis1 0.6s infinite;
      }

      &:nth-child(2) {
        left: 8px;
        animation: loader-ellipsis2 0.6s infinite;
      }

      &:nth-child(3) {
        left: 32px;
        animation: loader-ellipsis2 0.6s infinite;
      }

      &:nth-child(4) {
        left: 56px;
        animation: loader-ellipsis3 0.6s infinite;
      }
    }
 }


 @keyframes loader-ring {
   0% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(360deg);
   }
 }

 .loader-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 8px;
    border: 4px solid $whiteTrans50;
    border-radius: 50%;
    animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $whiteTrans50 transparent transparent transparent;

    & :nth-child(1) {
      animation-delay: -0.45s;
    }

    & :nth-child(2) {
      animation-delay: -0.3s;
    }

    & :nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}
