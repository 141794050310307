.arrow {
  &--right {
    transform: rotate(-45deg);
  }

  &--left {
    transform: rotate(135deg);
  }

  &--up {
    transform: rotate(-135deg);
  }

  &--down {
    transform: rotate(45deg);
  }
}
