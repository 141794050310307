@import "./style/main.scss";

.App {
  text-align: center;
}

.separator {
  margin: 10px auto;
  border-bottom: 1px solid $text-color;
  border-top: none;
  border-left: none;
  border-right: none;
}

.load-error {
  margin-top: 3rem;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  text-align: center;
}