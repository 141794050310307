@import "../../style/main.scss";

.header {
    position: fixed;
    bottom: 10%;
    left: 50%;
    width: $header-width;
    margin-left: - ($header-width / 2) - $header-padding;
    color: $text-color;
    text-transform: uppercase;
    z-index: 100;
    padding: $header-padding $header-padding 20px $header-padding;
  
    &:hover {
      background-color: $whiteTrans50;
      color: $text-color-revert;
      cursor: pointer;
      transition: all .2s ease-in-out;
  
      .separator {
        border-color: $text-color-revert;
        transition: all .2s ease-in-out;
      }
    }
  
    .title {
      font-size: $font-size-title;
      line-height: $font-size-title;
      letter-spacing: 2px;
      text-align: center;
    }
  
    .separator {
      width: 25%;
    }
  
    .subtitle {
      font-size: $font-size-sub-title;
      line-height: $font-size-sub-title;
      letter-spacing: 2px;
      text-align: center;
    }

    .text-reversed {
      display: inline-block;
      transform: rotateY(180deg);
      color: #e80909;
      font-size: $font-size-sub-title;
      line-height: $font-size-sub-title;
      margin-left: -3px;
      margin-right: 1px;
    }

    &.menu--opened {
        bottom: 85%;
    }
}


@media screen and (max-width: $mobile-width) {
  .header {
    width: $header-width-mobile;
    margin-left: - ($header-width-mobile / 2) - $header-padding;

    .title {
      font-size: $font-size-title-mobile;
    }
    .subtitle, .text-reversed {
      font-size: $font-size-sub-title-mobile;
    }

    &.menu--opened {
      bottom: 80%;
    }
  }
}