@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin arrow($size, $color) {
  border: solid $color;
  border-width: 0 $size $size 0;
  display: inline-block;
  padding: $size;
}

@mixin cross($size, $color, $thiness: 2px) {
  width: $size;
  height: $size;

  &::before, &::after {
    position: absolute;
    left: $size / 2;
    content: ' ';
    height: $size;
    width: $thiness;
    background: $color;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
