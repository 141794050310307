.background-slider__img {
    image-rendering: optimizeQuality;
    -ms-interpolation-mode: bicubic;
    border: 1px solid transparent;

    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: -5;

    transition: width .5s ease-in-out, height .5s ease-in-out;

    &--front {
        z-index: -2;
    }
}